/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AuthorizedHomeView from '../components/PatientAppointmentPage.vue';
import AuthorizedNewApptView from '../components/AppointmentPage.vue';
import ConfirmationView from '../components/ConfirmationPage.vue';
import ReviewPage from '../components/ReviewPage.vue';
// import WelcomeView from '../components/WelcomePage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomeView },
    { path: '/home', component: HomeView },
    { path: '/login', component: HomeView },
    { path: '/authorized', component: AuthorizedHomeView },
    { path: '/authorized/newAppt', component: AuthorizedNewApptView },
    { path: '/confirmation', component: ConfirmationView },
    { path: '/review', component: ReviewPage },
  ],
});

export default router;
