<template>
  <div class="container">
    <HeaderWidget />
    <div class="page-space"></div>
    <div class="pa-container">
      <div class="appointment-intro-container">
        <div class="heading-label">
          Book New Appointment
        </div>
        <button
          @click="newAppointment"
        >
          Book Now
        </button>
        <div class="spacer-w-line"></div>
        <div class="big-spacer"></div>
        <div class="heading-label">
          Upcoming Appointments
        </div>
        <AppointmentCard :post="post" v-for="(post, x) in posts" :key="x"></AppointmentCard>
      </div>
  </div>
  <div class="page-space"></div>
  <div class="bottom-container"></div>
  </div>
</template>
<script>
import AppointmentQuery from '../js/query';
import store from '../store';
import HeaderWidget from './widgets/HeaderWidget.vue';
import AppointmentCard from './widgets/UpcomingAppointmentCard.vue';
import DateTimeFormat from '../js/formatDate';

export default {
  components: { AppointmentCard, HeaderWidget },
  data() {
    return {
      PracticeName: store.state.conPracticeName,
      PracticeLogo: store.state.conPracticeLogo,
      posts: [],
    };
  },
  methods: {
    async getPatientAppointments() {
      const results = await AppointmentQuery.getPatientAppointments();
      console.log(results);
      for (let x = 0; x < results.length; x += 1) {
        const dt = new Date(results[x].StartDate);
        const dtd = dt.getDay();
        let hours = dt.getHours();
        let minutes = dt.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const dtt = `${hours}:${minutes} ${ampm}`;
        // eslint-disable-next-line no-await-in-loop
        const formattedDate = await DateTimeFormat.shortDate(results[x].StartDate.split(' ')[0]);
        console.log('Formatted Date: ', formattedDate.shortDate);
        this.posts[x] = {
          serviceType: results[x].ServiceTypeDescription,
          startDate: formattedDate.shortDate,
          startDay: store.state.WeekDay[dtd],
          startTime: dtt,
          officeName: results[x].OfficeName,
          doctorName: results[x].ProviderName,
        };
      }
      console.log('Results', this.posts);
    },
    async newAppointment() {
      this.$router.push('/authorized/newAppt');
    },
  },
  async mounted() {
    await this.getPatientAppointments();
  },
};
</script>
<style lang="scss" scoped>
.pa-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99vw;
  height: 100vh;
  padding-top: 10px;
  padding: 10px;
  border-radius: 15px;
  background: url('../assets/Backgrounds/Blue-Background-1.png');
  background-size: cover;
}

.patient-all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  contain: strict;
  width: 500px;
  height: 310px;
  background-color: #faf9f6;
  border-radius: 25px;
}

.appointment-intro-container{
  display: flex;
  position: fixed;
  top: 15%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding-bottom: 10px;
}

.spacer-w-line {
  width: 300px;
  height: 40px;
  border-bottom: 1px solid #fff;
}
.big-spacer {
  height: 30px;
}

</style>
