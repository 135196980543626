<template>
  <div class="container">
    <HeaderWidget />
    <div class="page-space"></div>
    <div class="confirm-container">
      <div class="instructions">
        Confirm Appointment
      </div>
      <div class="confirm-info-container">
        <div class="progress-bar">
          <div class="progress-meter-1"></div>
          <div class="progress-meter-2"></div>
          <div class="progress-meter-3"></div>
        </div>
        <div class="tab"></div>
        <NewAppointmentCard />
      </div>
    </div>
    <div class="page-space"></div>
    <div class="bottom-container"></div>
  </div>
</template>

<script>
import HeaderWidget from './widgets/HeaderWidget.vue';
import NewAppointmentCard from './widgets/NewAppointmentCard.vue';

export default {
  components: { HeaderWidget, NewAppointmentCard },
};
</script>

<style lang="scss" scoped>
.confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99vw;
  height: 100vh;
  padding-top: 100px;
  padding: 5px;
  border-radius: 15px;
  background: url('../assets/Backgrounds/Blue-Background-2.png');
  background-size: cover;
}

.confirm-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 350px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 5px;
}

.progress-bar {
  width: 100%;
  height: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.progress-meter-1 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-2 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-3 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #b3b3b3;
}
</style>
