<template>
  <div class="all-appointment-container">
    <div class="appointment-container-1">
      <div class="appointment-details">{{ post.startDay }}</div>
      <div class="appointment-details">{{ post.startDate }}</div>
      <div class="appointment-details">{{ post.startTime }}</div>
      <div class="appointment-details">{{ post.serviceType }}</div>
    </div>
    <div class="appointment-container-2">
      <div class="doctor-details">
        {{ post.doctorName }}
      </div>
      <div class="office-details">
        {{ post.officeName }}
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</template>

<script>
export default {
  props: ['post'],
};

</script>

<style lang="scss" scoped>
.all-appointment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 120px;
  border-radius: 15px;
}

.appointment-container-1 {
  display: grid;
  grid-template-columns: 135px 135px;
  grid-template-rows: 35px 35px;
  grid-row-gap: 1px;
  grid-column-gap: 15px;
  width: 315px;
  height: 80px;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.appointment-container-2 {
  display: flex;
  flex-direction: column;
  width: 315px;
  height: 70px;
  background-color: #fff;
  padding-left: 13px;
  padding-right: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.appointment-details {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 26px;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: lightgray;
  color: #00589C;
  font-size: 14px;
  font-weight: 700;
}

.doctor-details {
  font-size: 18px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
}

.office-details {
  font-size: 14px;
  font-weight: 300;
  color: grey;
}
.spacer {
  height: 15px;
}
</style>
