<template>
  <div class="container">
    <HeaderWidget/>
    <div class="page-space"></div>
    <div class="review-container">
      <div class="review-info-container">
        <div class="progress-bar">
          <div class="progress-meter-1"></div>
          <div class="progress-meter-2"></div>
          <div class="progress-meter-3"></div>
        </div>
        <div class="review-success-container">
          <div class="review-success-icon">
            <img :src="require('../assets/Icons/success-check-mark-1.png')" alt="Success Icon" />
          </div>
        </div>
        <div class="review-success-info">
          <div class="review-success-heading">
            Success!
          </div>
          You have scheduled an appointment with <strong>{{ doctor }} </strong>
          for <strong>{{ service }} </strong> on <strong>{{ date }}</strong>
          at <strong>{{ time }}</strong>.
        </div>
      </div>
      <div class="logout-disclaimer">
      Page will automatically logout and refresh in {{ countdown }} seconds.
    </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import HeaderWidget from './widgets/HeaderWidget.vue';

export default {
  components: { HeaderWidget },
  data() {
    return {
      doctor: store.state.conDoctor,
      service: store.state.conService,
      day: store.state.conDay,
      date: store.state.conDate,
      time: store.state.conTime,
      countdown: 30,
    };
  },
  methods: {
    logout() {
      console.log('Logged Out. Good bye 👋');
      localStorage.clear();
      store.state.sessionID = 0;
      store.state.conOffice = '';
      store.state.conDoctor = '';
      store.state.conService = '';
      store.state.conOfficeID = '';
      store.state.conServiceID = '';
      store.state.conDoctorID = '';
      store.state.conDate = '';
      store.state.conShortDate = '';
      store.state.conDay = '';
      store.state.conTime = '';
      store.state.conSendDate = '';
      this.$router.push('/');
    },
    logoutTimer() {
      setTimeout(() => this.logout(), 30 * 1000);
    },
    countdownTimer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countdownTimer();
        }, 1000);
      }
    },
  },
  mounted() {
    this.logoutTimer();
  },
  created() {
    this.countdownTimer();
  },
};
</script>

<style lang="scss" scoped>
.review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99vw;
  height: 100vh;
  padding-top: 100px;
  padding: 5px;
  border-radius: 15px;
  background: url('../assets/Backgrounds/Blue-Background-1.png');
  background-size: cover;
}

.review-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 350px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 5px;
}

.progress-bar {
  width: 100%;
  height: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.progress-meter-1 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-2 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-3 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.review-success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 125px;
}

.review-success-icon {
  display:flex;
  object-fit: contain;
  width: 120px;
  height: 120px;
}

.review-success-info {
  display: block;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
}

.review-success-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  color: darkblue;
}

.bold-type {
  font-weight: 700;
}

.logout-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding-top: 10px;
}
</style>
